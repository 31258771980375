<template>
	<div>
		<div class="row mb-4">
			<div class="col-md-2">
				<button class="btn complementary-btn" @click="today_date">Hoy</button>
			</div>
			<div class="col-md-4">
				<button class="btn light mr-2" @click="prev_month" title="Mes anterior"><</button>
				<div class="mr-2" style="display: inline-block">
					<div class="dropdown">
						<button class="btn btn-light dropdown-toggle" type="button" id="fecha-mes" data-toggle="dropdown" aria-expanded="false">{{ get_current_month }}</button>
						<div class="dropdown-menu" aria-labelledby="fecha-mes">
							<a class="dropdown-item" data-mes="01" @click="change_mount">Enero</a>
							<a class="dropdown-item" data-mes="02" @click="change_mount">Febrero</a>
							<a class="dropdown-item" data-mes="03" @click="change_mount">Marzo</a>
							<a class="dropdown-item" data-mes="04" @click="change_mount">Abril</a>
							<a class="dropdown-item" data-mes="05" @click="change_mount">Mayo</a>
							<a class="dropdown-item" data-mes="06" @click="change_mount">Junio</a>
							<a class="dropdown-item" data-mes="07" @click="change_mount">Julio</a>
							<a class="dropdown-item" data-mes="08" @click="change_mount">Agosto</a>
							<a class="dropdown-item" data-mes="09" @click="change_mount">Septiembre</a>
							<a class="dropdown-item" data-mes="10" @click="change_mount">Octubre</a>
							<a class="dropdown-item" data-mes="11" @click="change_mount">Noviembre</a>
							<a class="dropdown-item" data-mes="12" @click="change_mount">Diciembre</a>
						</div>
					</div>
				</div>

				<div class="mr-2" style="display: inline-block">
					<div class="dropdown">
						<button class="btn btn-light dropdown-toggle" type="button" id="fecha-anio" data-toggle="dropdown" aria-expanded="false">{{ get_current_year }}</button>
						<div class="dropdown-menu" aria-labelledby="fecha-anio">
							<a v-for="(anio, index) in rang_years" :key="index" :data-anio="anio" class="dropdown-item" @click="change_year">{{ anio }}</a>
						</div>
					</div>
				</div>

				<button class="btn light" @click="next_month" title="Mes siguente">></button>		
			</div>
			<div v-if="get_options('agregar_eventos')" class="col-md-4 col-lg-4 col-xl-3 offset-md-2 offset-lg-2 offset-xl-3">
				<button class="btn principal-btn" @click="view_modal_event(1)">Agregar evento</button>
			</div>
		</div>

		<table class="calendar" v-html="render"></table>

		<div class="row mt-4">
			<div class="col-sm-12" style="font-size: 0.85em;">
				<span class="calendar-before"></span> No se permite crear eventos <span class="calendar-normal"></span> Se permite crear eventos <span class="calendar-today"></span> Día actual <span class="calendar-active"></span> Día activo <span class="calendar-event-calendar-event-current"></span> Evento de la solicitud
			</div>
		</div>

		<Modal v-if="ver_evento != 0" @close="cancel_modal_event">
			<form @submit="agregar_envento">
				<div class="title">Evento</div>
				<div class="body">
					<div class="row form-group">
						<label for="titulo" class="col-form-label col-sm-2">Titulo</label>
						<div class="col-sm-10">
							<input v-if="evento.title === null" v-model="evento.title" type="text" name="titulo" id="titulo" class="form-control" :readonly="ver_evento!=1">
							<div v-else >{{ evento.title }}</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10">
							<input v-if="evento.title === null" v-model="evento.descript" type="text" name="descripcion" id="descripcion" class="form-control" :readonly="ver_evento!=1">
							<div v-else>{{ evento.descript }}</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="hora" class="col-form-label col-sm-2">Hora</label>
						<div class="col-sm-10"><input v-model="evento.date" type="datetime-local" name="hora" id="hora" class="form-control" :readonly="!get_options('editar_date') && !(options && options.evento && evento.solicitud_id == options.evento.solicitud_id)"></div>
					</div>
					<div class="row form-group">
						<label for="locacion" class="col-form-label col-sm-2">Ubicación</label>
						<div class="col-sm-10">
							<input v-model="evento.location" type="text" name="locacion" id="locacion" class="form-control" :readonly="!get_options('editar_location') && !(options && options.evento && evento.solicitud_id == options.evento.solicitud_id)">
						</div>
					</div>
					<div class="row form-group">
						<label for="" class="col-form-label col-sm-2">Invitados</label>
						<div class="col-sm-10">
							<div class="container-fluid list-invitados">
								<div class="row list-invitaod-title">
									<div class="col-sm-5">Nombre</div>
									<div class="col-sm-5">Email</div>
								</div>
								<div v-for="(tmpInvitado, index) in evento.attendees" :key="index" class="row list-invitados-item">
									<div class="col-sm-5">{{ tmpInvitado.name }}</div>
									<div class="col-sm-5">{{ tmpInvitado.email }}</div>
									<div v-if="get_options('agregar_invitados')" class="col-sm-1 text-center">
										<img src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar" title="Editar invitado" @click="editar_invitado(tmpInvitado)">
									</div>
									<div v-if="get_options('agregar_invitados')" class="col-sm-1 text-center">
										<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" title="Quitar invitado" @click="quitar_invitado(tmpInvitado.email)">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<div v-if="ver_evento==1" class="row">
						<div  class="col-sm-12 text-right">
							<button v-if="get_options('agregar_invitados')" type="button" class="btn secondary-btn" @click="ver_invitado=1">Agregar invitado</button>
							<button class="btn btn-success mr-2">Agregar</button>
							<button type="button" class="btn btn-secondary" @click="cancel_modal_event">Cancelar</button>
						</div>
					</div>
					<div v-else class="row">
						<div class="col-sm-12 text-right">
							<button v-if="this.options && this.options.evento && this.evento.solicitud_id == this.options.evento.solicitud_id" class="btn btn-danger mr-2" type="button" @click="cancelar_evento(evento.id)">Cancelar evento</button>
							<button v-if="this.options && this.options.evento && this.evento.solicitud_id == this.options.evento.solicitud_id" class="btn btn-success mr-2" type="button" @click="actualizar_evento(evento)">Actualizar evento</button>
							<button class="btn btn-info" type="button" @click="cancel_modal_event">Cerrar</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="ver_invitado!=0" :options="{width: '40vw'}" @close="cancelar_invitado">
			<form @submit="agregar_invitado">
				<div class="title">Invitado</div>
				<div class="body">
					<div class="row form-group">
						<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-10">
							<input v-model="invitado.name" type="text" name="nombre" id="nombre" class="form-control">
						</div>
					</div>
					<div class="row form-group">
						<label for="email" class="col-form-label col-sm-2">Email</label>
						<div class="col-sm-10">
							<input v-model="invitado.email" type="email" name="email" id="email" class="form-control">
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-2 offset-sm-8">
							<button class="btn principal-btn">Guardar</button>
						</div>
						<div class="col-sm-2">
							<button type="button" class="btn secondary-btn" @click="cancelar_invitado">Cancelar</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from './Modal';

	export default {
		components: {
			Modal
		}
		,data: function() {
			return {
				total_col: 0
				,year: null
				,month: null
				,ver_evento: 0
				,evento: {
					hash: null
					,title: null
					,descript: null
					,date: null
					,location: null
					,attendees: []
				}
				,eventos_reales: []
				,invitado: {
					nombre: null
					,email: null
				}
				,ver_invitado: 0
				,total_eventos: []
				,asigancion_eventos: false
				,eventos_asignados: false
				,solicitud: null
			}
		}
		,props: {
			eventos: {
				type: Array
				,default: () => {
					return []
				}
			}
			,options: {
				type: Object
				,default: () => ({})
			}
			,finalizar: false
		}
		,updated: function() {
			this.add_events();
		}
		,mounted: function() {
			this.add_events();
		}
		,methods: {
			get_options: function(option) {
				let value;
				if (typeof(this.options[option]) != 'undefined') {
					this.$log.info('el valor '+option+' existe: ', this.options[option]);
					value =  this.options[option];
				}else {
					switch(option) {
						case 'agregar_eventos_js':
							value = true;
							break;
						case 'agregar_eventos':
							value = true;
							break;
						case 'agregar_invitados':
							value = true;
							break;
						case 'invitados':
							value = [{
								id: this.$helper.strRand(8, 'numeric')
								,name: this.$auth.getUser().nombre
								,email: this.$auth.getUser().email
							}];
							break;
						case 'evento':
							value = {
								title: 'NA',
								descript: 'NA',
								location: 'NA',
								invitados: [{
									id: this.$helper.strRand(8, 'numeric')
									,name: this.$auth.getUser().nombre
									,email: this.$auth.getUser().email
								}]
							}
						case 'events_before':
							value = false;
							break;
						case 'edit_date':
							value = true;
							break;
						case 'edit_location':
							value = true;
							break;
					}
				}

				// this.$log.info('calendarOption: ', option, value);

				return value;
			}
			,get_day_of_month: function(day) {
				let tmpDay = day < 10 ? '0'+day : day;
				let today = this.$moment().format('YYYY-MM-DD');

				let date = this.year+'-'+this.month+'-'+tmpDay;
				let isToday = date == today ? ' calendar-today' : '';
				let isBeforeDay = this.$moment(date).isBefore(today) ? ' calendar-before' : '';

				if (this.get_options('events_before'))
					isBeforeDay = '';

				let td = '<td class="calendar-day-content'+isToday+isBeforeDay+'"><div class="calendar-day">'+day+'</div><div class="calendar-content" data-date="'+date+'">';

				// this.$log.info('eventos',this.eventos_reales);
				for(let i=0; i<this.total_eventos.length; i++) {
					if (this.$moment(this.total_eventos[i].date).format('YYYY-MM-DD') == date) {
						this.$log.info('asignar evento: ',this.total_eventos[i]);
						let is_same_event = (this.options && this.options.evento && this.total_eventos[i].solicitud_id == this.options.evento.solicitud_id ? ' calendar-event-current' : '');
						td += '<div class="calendar-event'+is_same_event+'" data-event="'+this.$helper.toJson(this.total_eventos[i])+'">'+this.total_eventos[i].title+'</div>';
					}
				}

				td += '</div></td>';

				return td;
			}
			,prev_month: function() {
				if (this.month == 1) {
					this.year = parseInt(this.year) - 1;
					this.month = 12;
				}else
					this.month = parseInt(this.month) - 1;

				if (this.month < 10)
					this.month = '0'+this.month;

				this.eventos_asignados = false;

				// this.$log.info('date', this.year+'-'+this.month);
			}
			,next_month: function() {
				if (this.month == 12) {
					this.year = parseInt(this.year) + 1;
					this.month = 1;
				}else
					this.month = parseInt(this.month) + 1;

				if (this.month < 10)
					this.month = '0'+this.month;

				this.eventos_asignados = false;

				// this.$log.info('date', this.year+'-'+this.month);
			}
			,today_date: function() {
				this.year = this.$moment().format('YYYY');
				this.month = this.$moment().format('MM');
			}
			,add_events: function() {
				let enable = this.get_options('agregar_eventos_js');

				this.$log.info('eventos_asignados', this.eventos_asignados);
				if (enable) {
					this.$log.info('asignamos eventos');
					let days = document.querySelectorAll('td.calendar-day-content');
					let parent = this;

					for(let i=0; i<days.length; i++) {
						days[i].removeEventListener('click', this.show_day);
						days[i].addEventListener('click', this.show_day);
					}

					// this.eventos_asignados = true;
				}
			}
			,show_day: function(e) {
				let type = e.target.getAttribute('class');

				if (type.indexOf(' ') > -1)
					type = type.split(' ')[0];

				this.$log.info('show_day_type', type);

				switch(type) {
					case 'calendar-event':
						this.evento = JSON.parse(decodeURI(e.target.getAttribute('data-event')));
						this.evento.date = this.$moment(this.evento.date).format('YYYY-MM-DD\THH:mm');
						this.evento.attendees = this.evento.invitados;
						this.$log.info('evento_asumo', this.evento);
						this.ver_evento = 2;
						break;
					case 'calendar-content':
						let agregar_evento = this.get_options('agregar_eventos');
						this.$log.info('agregar_evento', agregar_evento);

						if (!agregar_evento)
							return '';	

						this.$log.info('agregamos evento');
						let events_before = this.get_options('events_before');
						this.$log.info('events_before', events_before);

						let date = e.target.getAttribute('data-date')
						+ 'T' + this.$moment().format('HH:mm');

						let isValidDate = true;

						if (!events_before) {
							this.$log.info('el dia es anterior?');
							isValidDate = this.$moment(date).isSameOrAfter(this.$moment(),'day');
						}

						this.$log.info('isValidDate', isValidDate);
						if (!isValidDate)
							return this.$helper.showMessage('Error','No puedes agregar un evento en fechas anteriores al día actual','error','alert');

						this.evento.title = this.get_options('evento').title;
						this.evento.descript = this.get_options('evento').descript;
						this.evento.date = date;
						this.evento.location = this.get_options('evento').location;
						this.ver_evento = 1;

						let invitados = Array.from(this.get_options('invitados'));

						for(let i=0; i<invitados.length; i++) {
							if (!invitados[i].id)
								invitados[i].id = this.$helper.strRand(8,'numeric');
						}

						this.evento.attendees = invitados;
						break;
				}
			}

			//Modal
			,view_modal_event: function(tipo) {
				if (tipo == 1) {
					this.evento.date = this.$moment().format('YYYY-MM-DD\THH:mm');
					this.evento.title = this.get_options('evento').title;
					this.evento.descript = this.get_options('evento').descript;
					this.evento.location = this.get_options('evento').location;
					this.ver_evento = 1;

					let tmpInvitados = Array.from(this.get_options('invitados'));

					for(let i=0; i<tmpInvitados.length; i++) {
						if (!tmpInvitados[i].id)
							tmpInvitados[i].id = this.$helper.strRand(8,'numeric');
					}

					this.evento.attendees = tmpInvitados;
				}
			}
			,cancel_modal_event: function() {
				this.evento = {
					title: null
					,descript: null
					,date: null
					,locatin: null
					,attendees: []
				}
				this.invitado = {
					nombre: null
					,email: null
				}
				this.ver_evento = 0;
			}
			,agregar_envento: function(e) {
				if (e) e.preventDefault();

				if (!this.evento.title || this.evento.title.length < 3) {
					this.$helper.showMessage('Error','Tienes que especificar un titulo por lo menos de 3 caracteres', 'error', 'alert');
					return;
				}

				if (!this.evento.descript || this.evento.descript.length < 3) {
					this.$helper.showMessage('Error','Tienes que especificar una descripción por lo menos de 3 caracteres', 'error', 'alert');
					return;
				}

				if (this.evento.date == '' || this.evento.date == null) {
					this.$helper.showMessage('Error','Tienes que especificar la fecha del evento', 'error', 'alert');
					return;
				}

				if (this.evento.location == '' || this.evento.location == null) {
					this.$helper.showMessage('Error','Tienes que especificar la locación del evento', 'error', 'alert');
					return;
				}

				let events_before = this.get_options('events_before');
        let is_event_before = this.$moment(this.evento.date).isBefore( this.$moment().format('YYYY-MM-DD\THH:mm') );

				if (is_event_before)
          return this.$helper.showMessage('Error','No puedes agregar evento en fechas anteriores al día actual','error','alert');

				this.evento.hash = this.$helper.strRand(45);

				this.asigancion_eventos = false;

				// this.total_eventos.push(this.evento);
				this.$emit('nEvento', this.evento);
				this.cancel_modal_event();
			}
			,actualizar_evento: function(evento) {
				this.$emit('upEvent',evento);
				this.cancel_modal_event();
			}
			,cancelar_evento: function(id) {
				this.$emit('caEvent',id);
				this.cancel_modal_event();
			}
			,editar_invitado: function(invitado) {
				this.invitado = invitado;
				this.ver_invitado = 2;
			}
			,agregar_invitado: function(e) {
				if (e) e.preventDefault();

				if (this.ver_invitado == 1) {
					let encontrado = false;
					for(let i=0; i<this.evento.attendees.length; i++) {
						if (this.evento.attendees[i].email == this.invitado.email) {
							encontrado = true;
							i = this.evento.attendees.length;
						}
					}

					if (!encontrado)
						this.evento.attendees.push(this.invitado);
					else {
						this.$helper.showMessage('Error','Ya existe un invitado con ese correo', 'error', 'alert');
						return;
					}
				}else {

					for(let i=0; i<this.evento.attendees.length; i++) {
						if (this.evento.attendees[i].email == this.invitado.email) {
							this.evento.attendees[i] = this.invitado;
						}
					}
				}

				this.cancelar_invitado();
			}
			,cancelar_invitado: function() {
				this.invitado = {
					nombre: null
					,email: null
				}
				this.ver_invitado = 0;
			}
			,quitar_invitado: function(email) {
				let tpmInvitados = [];

				for(let i=0; i<this.evento.attendees.length; i++) {
					if (this.evento.attendees[i].email != email) {
						tpmInvitados.push(this.evento.attendees[i]);
					}
				}

				this.evento.attendees = tpmInvitados;
			}
			,change_mount: function(e) {
				this.$log.info('mes',e.target.dataset.mes);
				this.month = e.target.dataset.mes;

				this.eventos_asignados = false;
			}
			,change_year: function(e) {
				this.$log.info('anio',e.target.dataset.anio);
				this.year = e.target.dataset.anio;

				this.eventos_asignados = false;
			}
		}
		,computed: {
			render: function() {
				let calendar = '';
				calendar += '<tr class="title">';
				calendar += '<th>Lunes</th>';
				calendar += '<th>Martes</th>';
				calendar += '<th>Miércoles</th>';
				calendar += '<th>Jueves</th>';
				calendar += '<th>Viernes</th>';
				calendar += '<th>Sábado</th>';
				calendar += '<th>Domingo</th>';
				calendar += '</tr>';

				if (!this.year)
					this.year = this.year = this.$moment().format('YYYY');

				if (!this.month)
					this.month = this.$moment().format('MM');

				let lastDayOfMonth = this.$moment(this.year+'-'+this.month+'-01').endOf('month').format('D');

				let diaInicial = this.year+'-'+this.month+'-01';
				let numWeekDay = parseInt(this.$moment(diaInicial).format('e'));

				// this.$log.info('numEvents: ',this.total_eventos.length);

				let currentDay = 1;
				for(let i=0; i<10; i++) {
					calendar += '<tr>';
					for(let a=0; a<7; a++) {
						if (i == 0) {
							if (a >= numWeekDay) {
								calendar += this.get_day_of_month(currentDay);
								currentDay++;
							}else {
								calendar += '<td class="calendar-day-content-empty">&nbsp;</td>';
							}
						}else {
							if (currentDay <= lastDayOfMonth) {
								calendar += this.get_day_of_month(currentDay);
								currentDay++;
							}else {
								calendar += '<td class="calendar-day-content-empty">&nbsp;</td>';
							}
						}
					}
					calendar += '</tr>';

					if (currentDay > lastDayOfMonth)
						i=10;
				}

				return calendar;
			}
			,get_current_month: function() {
				if (!this.year)
					this.year = this.$moment().format('YYYY');

				if (!this.month)
					this.month = this.$moment().format('MM');

				let mes = this.$moment(this.year+'-'+this.month+'-01').format('MMMM');

				return mes.charAt(0).toUpperCase()+mes.slice(1);
			}
			,get_current_year: function() {
				if (!this.year)
					this.year = this.$moment().format('YYYY');


				return this.year;
			}
			,total_rows: function() {
				if (!this.year)
					this.year = this.year = this.$moment().format('YYYY');

				if (!this.month)
					this.month = this.$moment().format('MM');

				let fecha = this.year+'-'+this.month+'-01';
				let diaSemana = parseInt(this.$moment(fecha).format('e'));
				// this.$log.info('diaSemana', diaSemana)

				let totalDias = parseInt(this.$moment(fecha).endOf('month').format('DD')) + diaSemana;
				// this.$log.info('totalDias', totalDias);


				return Math.ceil(totalDias / 7);
			}
			,rang_years: function() {
				let years = [];

				if (!this.year)
					this.year = this.$moment().format('YYYY');

				let currenYear = parseInt(this.year);
				let anioExtras = 5;

				let yearStart = currenYear-anioExtras;
				let yearEnd = currenYear+anioExtras;

				this.$log.info('yearStart', yearStart);
				this.$log.info('yearEnd', yearEnd);

				for(let i=yearStart; i<=yearEnd; i++) {
					years.push(i);
				}

				return years;
			}
		}
		,watch: {
			eventos: {
				handler: function(val) {
					this.asigancion_eventos = true;
					this.total_eventos = val;
				}
				,deep: true
			}
			// ,finalizar(finalizar) {
			// 	if (finalizar && this.evento.hash) {
			// 		this.$log.info('agregar evento');
			// 		// this.total_eventos.push(this.evento);
			// 	}
			// }
		}
	}
</script>

<style lang="scss">
  table.calendar {
    width: 100%;

    tr {
      td.calendar-day-content {
        width: calc(100% / 7);
        border: solid 1px #7aabf4;
        vertical-align: top !important;
        position: relative;

        .calendar-day {
          position: absolute;
          top: 5px;
          left: 5px;
        }

        .calendar-content {
          min-height: 80px;
          max-height: 180px;
          margin-top: 30px;
          overflow: auto;

          .calendar-event {
            background-color: #a7c9f9;
            padding: 5px 8px;
            border: solid 1px #5991e0;
            margin: 0px 3px;
            margin-bottom: 5px;
          }

		  .calendar-event-current {
            background-color: #e4ffed;
            border: solid 1px #47d476;
          }

          .calendar-event:hover {
            background-color: #d6e7ff;
          }

          .calendar-event:last-child {
            margin-bottom: 0px;
          }
        }
      }

      td.calendar-today {
        background-color: #e2f9de;
      }

      td.calendar-before {
        background-color: #fff5e5 !important;
      }

      td.calendar-day-content:hover {
        background-color: #f4f7ca;
      }

      td.calendar-day-content-empty {
        width: calc(100% / 7);
        border: solid 1px #7aabf4;
      }
    }

    .title {
      background-color: #1561D3;
      color: #fff;

      th {
        font-size: 1.3em;
        text-align: center;
        padding: 5px 10px;
      }
    }
  }

  .list-invitados {
    .list-invitaod-title {
      background-color: #1561D3;
      color: #fff;
    }

    .list-invitados-item {
      img {
        width: 15px;
      }
    }

    .list-invitados-item:nth-child(2n+1) {
      background-color: #e5e5e5;
    }
  }

  span {
    margin-left: 20px;
  }

  span:first-child {
    margin-left: 0px;
  }

  span.calendar-before {
    width: 20px !important;
    height: 10px !important;
    background-color: #fff5e5 !important;
    border: solid 1px #e5d3b7;
    display: inline-block;
  }

  span.calendar-normal {
    width: 20px !important;
    height: 10px !important;
    border: solid 1px #e5e5e5;
    display: inline-block;
  }

  span.calendar-today {
    width: 20px !important;
    height: 10px !important;
    background-color: #e2f9de !important;
    border: solid 1px #b8e0b1;
    display: inline-block;
  }

  span.calendar-active {
    width: 20px !important;
    height: 10px !important;
    background-color: #f4f7ca !important;
    border: solid 1px #d8dba0;
    display: inline-block;
  }

  span.calendar-event-calendar-event-current {
	background-color: #e4ffed;
	border: solid 1px #47d476;
    display: inline-block;
    width: 20px !important;
    height: 10px !important;
  }
</style>